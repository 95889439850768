<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-if="!loading" my-2>
            <form @submit.prevent="update_categories" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="number" v-model="categories.category_sort"
                            label="Sort" outlined class="mx-1" filled required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="categories.category_name"
                            :label="$store.getters.lang.data.category_name" outlined class="mx-1" filled required>
                        </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="categories.category_color"
                            :label="$store.getters.lang.data.category_color" outlined class="mx-1" filled required>
                        </v-text-field>
                    </v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field :append-icon="categories.category_icon" rounded type="text" v-model="categories.category_icon"
                            :label="$store.getters.lang.data.category_icon" outlined class="mx-1" filled required>
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg6 xl6 md3 sm4>
                        <v-textarea rows="1" auto-grow rounded type="text" v-model="categories.category_keywords"
                            :label="$store.getters.lang.data.category_keywords" outlined class="mx-1" filled required>
                        </v-textarea>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <input style="display:none" ref="file"  type="file" @change="uploadFile"
                                />
                            <v-btn rounded x-large color="primary" class="mx-1" @click="openFile">
                                <v-icon>mdi-image</v-icon>
                                <span>{{$store.getters.lang.data.category_icon}}</span>
                            </v-btn>
                        </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-avatar
                                size="90"
                            >
                                <img :src="svg" alt="not uploades">
                            </v-avatar>
                        </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" rounded class="mx-1" type="submit" x-large >
                            {{$store.getters.lang.data.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>

<script>
    import controller from './../../controller/categories'
    import axios from 'axios'
    export default {
        data() {
            return {
                categories: {},
                id: '',
                svg : '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },

            }
        },
        computed: {


        },
        methods: {
            openFile() {
                this.$refs.file.click()
            },
            uploadFile(e){
                if (!e.target.files[0]) {
                    return
                }
                this.img_loading = true
                let formData = new FormData();
                formData.append('category_id', this.id);
                formData.append('file', e.target.files[0]);
                axios.post("categories/uploadSvg.php",
                        formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((data) => {
                        if (data.data.status == 'ok') {
                            this.get_categories()
                            this.svg = this.$api + 'uploads/'+this.categories.category_image
                        }
                        this.img_loading = false
                    })
                    .catch(() => {
                        this.img_loading = false
                        console.log('FAILURE!!');
                    });
            },
            get_categories() {
                this.loading = true
                controller.getOne(this.id).then(r => {
                    this.categories = r.data.row
                    this.loading = false
                    this.svg = this.$api + '/uploads/'+this.categories.category_image
                }).catch(e => {
                    console.log(e);
                })
            },
            update_categories() {
                controller.update_categories(this.categories).then(r => {
                    if (r.data) {
                        this.snackbar = {
                            value: true,
                            text: 'Updated',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Not Updated',
                            color: 'error'
                        }
                    }
                })
            },

        },
        mounted() {
            this.id = this.$route.params.id
            this.get_categories()
        },
    }
</script>